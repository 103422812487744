var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row",class:{'mt-1': !_vm.hideMetaFields, 'mt-5': _vm.hideMetaFields}},[_c('div',{staticClass:"col-8 col-md-6 mt-5 mx-auto"},[_vm._m(0),_c('div',{staticClass:"card mt-3"},[_c('div',{staticClass:"card-body"},[_c('validation-observer',{ref:"formStatus",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.submit()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(!_vm.hideMetaFields)?_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"tenantInput"}},[_vm._v("Kundenkennung")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tenant),expression:"tenant"}],staticClass:"form-control",attrs:{"id":"tenantInput","type":"text","autofocus":""},domProps:{"value":(_vm.tenant)},on:{"input":function($event){if($event.target.composing){ return; }_vm.tenant=$event.target.value}}})]):_vm._e(),(!_vm.hideMetaFields)?_c('div',{staticClass:"form-group"},[_c('validation-provider',{attrs:{"name":"API Key","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"keyInput"}},[_vm._v("API Key")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.key),expression:"key"}],staticClass:"form-control",class:{'is-invalid': errors.length > 0},attrs:{"id":"keyInput","type":"password"},domProps:{"value":(_vm.key)},on:{"input":function($event){if($event.target.composing){ return; }_vm.key=$event.target.value}}}),_c('form-errors',{attrs:{"errors":errors}})]}}],null,true)})],1):_vm._e(),_c('validation-provider',{attrs:{"vid":"Username","name":"Nutzername","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"nameInput"}},[_vm._v("Nutzername")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.username),expression:"username"}],staticClass:"form-control",class:{'is-invalid': errors.length > 0},attrs:{"id":"nameInput","type":"text","autocomplete":"current-password","autofocus":""},domProps:{"value":(_vm.username)},on:{"input":function($event){if($event.target.composing){ return; }_vm.username=$event.target.value}}}),_c('form-errors',{attrs:{"errors":errors}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"vid":"Password","name":"Passwort","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"passwordInput"}},[_vm._v("Passwort")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control",class:{'is-invalid': errors.length > 0},attrs:{"id":"passwordInput","type":"password","autocomplete":"current-password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('form-errors',{attrs:{"errors":errors}})],1)]}}],null,true)}),_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"btn btn-primary btn-block",attrs:{"type":"submit","name":"general","disabled":_vm.loading || invalid}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"spinner-border spinner-border-sm mr-2"},[_vm._v(" ... ")]),_c('span',[_vm._v("Login")])])])]}}])})],1)]),(_vm.hideMetaFields)?_c('div',{staticClass:"text-center mt-3"},[_c('p',{staticClass:"small"},[_vm._v("Kundenkennung: "+_vm._s(this.tenant)+" "),_c('button',{staticClass:"btn btn-outline-secondary btn-sm ml-3 link small",on:{"click":function($event){_vm.hideMetaFields = false}}},[_vm._v("ändern ")])])]):_vm._e()])]),_vm._m(1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{staticClass:"d-block ml-auto mr-auto",attrs:{"alt":"logo","src":require("@/assets/logo-purple.svg"),"align":"middle","width":"50%"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row position-relative justify-content-center"},[_c('div',{staticClass:"col-5 col-md-3 col-lg-2 text-center"},[_c('a',{attrs:{"href":"https://play.google.com/store/apps/details?id=de.lipalabs.thea&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1","target":"_new"}},[_c('img',{attrs:{"alt":"Jetzt bei Google Play","width":"100%","height":"auto","src":"https://play.google.com/intl/en_us/badges/static/images/badges/de_badge_web_generic.png"}})])])])}]

export { render, staticRenderFns }