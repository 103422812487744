<template>
  <div>
    <div class="row"
         :class="{'mt-1': !hideMetaFields, 'mt-5': hideMetaFields}">
      <div class="col-8 col-md-6 mt-5 mx-auto">
        <div>
          <img alt="logo"
               src="@/assets/logo-purple.svg"
               align="middle"
               width="50%"
               class="d-block ml-auto mr-auto"/>
        </div>
        <div class="card mt-3">
          <div class="card-body">
            <validation-observer tag="form" v-slot="{invalid}" ref="formStatus" @submit.prevent="submit()">
              <div v-if="!hideMetaFields"
                   class="form-group">
                <label for="tenantInput">Kundenkennung</label>
                <input
                    v-model="tenant"
                    id="tenantInput"
                    class="form-control"
                    type="text"
                    autofocus/>
              </div>
              <div v-if="!hideMetaFields"
                   class="form-group">
                <validation-provider name="API Key" rules="required" v-slot="{errors}">
                  <label for="keyInput">API Key</label>
                  <input
                      v-model="key"
                      id="keyInput"
                      class="form-control"
                      type="password"
                      :class="{'is-invalid': errors.length > 0}"/>
                  <form-errors :errors="errors"/>
                </validation-provider>
              </div>
              <validation-provider vid="Username" name="Nutzername" rules="required" v-slot="{errors}">
                <div class="form-group">
                  <label for="nameInput">Nutzername</label>
                  <input
                      v-model="username"
                      id="nameInput"
                      class="form-control"
                      type="text"
                      autocomplete="current-password"
                      autofocus
                      :class="{'is-invalid': errors.length > 0}"/>
                  <form-errors :errors="errors"/>
                </div>
              </validation-provider>
              <validation-provider vid="Password" name="Passwort" rules="required" v-slot="{errors}">
                <div class="form-group">
                  <label for="passwordInput">Passwort</label>
                  <input
                      v-model="password"
                      id="passwordInput"
                      class="form-control"
                      type="password"
                      autocomplete="current-password"
                      :class="{'is-invalid': errors.length > 0}"/>
                  <form-errors :errors="errors"/>
                </div>
              </validation-provider>
              <div class="form-group">
                <button type="submit"
                        name="general"
                        class="btn btn-primary btn-block"
                        :disabled="loading || invalid">
                    <span v-show="loading"
                          class="spinner-border spinner-border-sm mr-2">
                      ...
                    </span>
                  <span>Login</span>
                </button>
              </div>
            </validation-observer>
          </div>
        </div>
        <div v-if="hideMetaFields" class="text-center mt-3">
          <p class="small">Kundenkennung: {{ this.tenant }}
            <button class="btn btn-outline-secondary btn-sm ml-3 link small" @click="hideMetaFields = false">ändern
            </button>
          </p>
        </div>
      </div>
    </div>
    <div class="row position-relative justify-content-center">
      <div class="col-5 col-md-3 col-lg-2 text-center">
        <a href='https://play.google.com/store/apps/details?id=de.lipalabs.thea&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
           target="_new">
          <img
              alt='Jetzt bei Google Play'
              width="100%"
              height="auto"
              src='https://play.google.com/intl/en_us/badges/static/images/badges/de_badge_web_generic.png'/></a>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import authenticationService from "@/services/authentication.service"
import handleFormError from "@/utils/form-error-converter";
import FormErrors from "@/components/common/FormErrors";

export default {
  components: {FormErrors},
  data() {
    return {
      username: null,
      password: null,
      key: null,
      tenant: null,
      loading: false,
      hideMetaFields: true
    }
  },
  computed: {
    ...mapGetters(['loggedIn']),
  },
  methods: {
    submit() {
      this.loading = true
      if (this.key)
        this.$store.commit('apiKeyDetermined', this.key)
      this.$store.commit('tenantDetermined', this.tenant)
      authenticationService.login(this.username, this.password).then(token => {
        console.log('authenticationService.login() succeeded with token ' + token)
      }).catch(error => {
        if (!handleFormError(error, this.$refs.formStatus))
          this.handleError(error)
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted() {
    this.key = this.$store.getters.apiKey
    this.tenant = this.$store.getters.tenant
    if (!this.key || this.tenant == null || this.tenant == undefined)
      this.hideMetaFields = false
  }
}
</script>
