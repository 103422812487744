<template>
  <div class="container h-100">
    <login></login>
  </div>
</template>

<script>
import Login from '../components/login/Login'

export default {
  components: {
    login: Login
  }
}
</script>
